import { template as template_b02b627c57bf45ef9b25dfcb02ea721f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_b02b627c57bf45ef9b25dfcb02ea721f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
