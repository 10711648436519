import { template as template_7ca5c69b2af0403aa4c64d192a79b8f0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7ca5c69b2af0403aa4c64d192a79b8f0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
