import { template as template_8be919da6e394897abc6bef48ec098e9 } from "@ember/template-compiler";
const WelcomeHeader = template_8be919da6e394897abc6bef48ec098e9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
